import React,{useCallback,useState,useEffect} from 'react'
import axios from 'axios';
import BaseURL from '../../../components/Other/BaseURL';
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import {Placeholder} from 'react-bootstrap'


function ContactOverview(){
    
const navigate = useNavigate();

const [ContactUsData, setContactUsData] = useState([])

const [ContactUsDataLoading, setContactUsDataLoading] = useState(true)
const [ContactUsDataLoadingNewCount, setContactUsDataLoadingNewCount] = useState(true)

const [JoinWodoNewQueryCount, setJoinWodoNewQueryCount] = useState(0);
const [LeadQueryNewQueryCount, setLeadQueryNewQueryCount] = useState(0);
const [PrQueryNewQueryCount, setPrQueryNewQueryCount] = useState(0);
const [DevQueryNewQueryCount, setDevQueryNewQueryCount] = useState(0)



const DataRefreshNewQueryCount = useCallback(async() => {

    await axios.get(BaseURL+'/contact-us/new-query-count',{})
    .then((res)=>{   
        // console.log(res.data.data);
        setJoinWodoNewQueryCount(res.data.data[0].joinwodo_count);
        setLeadQueryNewQueryCount(res.data.data[0].leadQuery_count);
        setPrQueryNewQueryCount(res.data.data[0].prQuery_count);
        setDevQueryNewQueryCount(res.data.data[0].devQuery_count);
        setContactUsDataLoadingNewCount(false)
    })
    .catch((err)=>{console.log(err)})

}, [])

useEffect(() => {
  DataRefreshNewQueryCount();
}, [DataRefreshNewQueryCount]);

const DataRefresh = useCallback(async() => {

    await axios.get(BaseURL+'/contact-us-overview/',{})
    .then((res)=>{   
        console.log(res.data.data);
        setContactUsData(res.data.data)
        setContactUsDataLoading(false)
    })
    .catch((err)=>{console.log(err)})

}, [])

useEffect(() => {
  DataRefresh();
}, [DataRefresh]);

let joinWodo=[];
ContactUsData.map((item,index) => {
    if(item.table_name=="contactUs"){
        if(item.status=="follow up"){
            joinWodo.push({ ['follow_up']: item.count });
        }else{
            joinWodo.push({ [item.status]: item.count });
        }
    }
}

)

function handleOverviewContactUs(tab){
    Cookies.set('tabContactUsStatus', tab);
    navigate('/contact-us')
}

console.log(joinWodo);


const filteredDataContactUs = ContactUsData.filter(item => item.table_name === "contactUs");

const statusCountsContactUs = filteredDataContactUs.reduce((acc, item) => {
    acc[item.status] = (acc[item.status] || 0) + item.count;
    return acc;
}, {});

const filteredDataLeadQuery = ContactUsData.filter(item => item.table_name === "leadQuery");

const statusCountsLeadQuery = filteredDataLeadQuery.reduce((acc, item) => {
    acc[item.status] = (acc[item.status] || 0) + item.count;
    return acc;
}, {});

const filteredDataPrQuery = ContactUsData.filter(item => item.table_name === "prQuery");

const statusCountsPrQuery = filteredDataPrQuery.reduce((acc, item) => {
    acc[item.status] = (acc[item.status] || 0) + item.count;
    return acc;
}, {});

const filteredDataDevQuery = ContactUsData.filter(item => item.table_name === "devQuery");

const statusCountsDevQuery = filteredDataDevQuery.reduce((acc, item) => {
    acc[item.status] = (acc[item.status] || 0) + item.count;
    return acc;
}, {});


    return(
        <>
            <div className=''>
                <h5>Contact Us</h5>
                <div className="grid-overview-container">
                    <div className="grid-overview-item card_overview"  onClick={()=>handleOverviewContactUs('join-wodo')}>
                        <h6>Join Wodo</h6>
                        <div className='card_hr'></div>

                        {/* <h1>19</h1> */}
                        <div className="row">
                            <div className="col">
                                <ul className='list-unstyled'>
                                {
                                    ContactUsDataLoading===true?(
                                        <>
                                            <Placeholder animation="glow">
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} /> 
                                            </Placeholder> 
                                        </>
                                    ):
                                    (
                                        <>
                                            <li>Pending: {statusCountsContactUs["pending"] || 0}</li>
                                            <li>Follow up: {statusCountsContactUs["follow up"] || 0}</li>
                                            <li>Rejected: {statusCountsContactUs["rejected"] || 0}</li>
                                            <li>Closed: {statusCountsContactUs["closed"] || 0}</li>
                                        </>

                                    )
                                }
                            </ul>
                            </div>
                            <div className="col verticle_center_dashboard" style={{fontSize:'4rem',fontWeight:600}}>
                                {/* {ContactUsDataLoadingNewCount} */}
                                {ContactUsDataLoadingNewCount===true?
                                    (
                                        <Placeholder animation="glow">
                                            <Placeholder xs={8} />  
                                        </Placeholder>
                                    ):
                                    (
                                        JoinWodoNewQueryCount
                                    )
                                }
                                

                            </div>
                        </div>
                    </div>

                    <div className="grid-overview-item card_overview" onClick={()=>handleOverviewContactUs('lead-query')}>
                        <h6>Leads Query</h6>
                        <div className='card_hr'></div>
                        <div className="row">
                            <div className="col">
                            <ul className='list-unstyled'>
                            {
                                    ContactUsDataLoading===true?(
                                        <>
                                            <Placeholder animation="glow">
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} /> 
                                            </Placeholder> 
                                        </>
                                    ):
                                    (
                                        <>
                                            <li>Pending: {statusCountsLeadQuery["pending"] || 0}</li>
                                            <li>Follow up: {statusCountsLeadQuery["follow up"] || 0}</li>
                                            <li>Rejected: {statusCountsLeadQuery["rejected"] || 0}</li>
                                            <li>Closed: {statusCountsLeadQuery["closed"] || 0}</li>
                                        </>

                                    )
                                }
                        </ul>
                            </div>
                            <div className="col verticle_center_dashboard" style={{fontSize:'4rem',fontWeight:600}}>
                                {LeadQueryNewQueryCount}
                            </div>
                        </div>
                        {/* <h1>19</h1> */}

                    </div>

                    <div className="grid-overview-item card_overview" onClick={()=>handleOverviewContactUs('pr-query')}>
                        <h6>Pr Query</h6>
                        <div className='card_hr'></div>

                        {/* <h1>19</h1> */}
                        <div className="row">
                            <div className="col">
                            <ul className='list-unstyled'>
                            {
                                    ContactUsDataLoading===true?(
                                        <>
                                            <Placeholder animation="glow">
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} /> 
                                            </Placeholder> 
                                        </>
                                    ):
                                    (
                                        <>
                                            <li>Pending: {statusCountsPrQuery["pending"] || 0}</li>
                                            <li>Follow up: {statusCountsPrQuery["follow up"] || 0}</li>
                                            <li>Rejected: {statusCountsPrQuery["rejected"] || 0}</li>
                                            <li>Closed: {statusCountsPrQuery["closed"] || 0}</li>
                                        </>

                                    )
                                }
                                </ul>
                            </div>
                            <div className="col verticle_center_dashboard" style={{fontSize:'4rem',fontWeight:600}}>
                                {PrQueryNewQueryCount}
                            </div>
                            
                        </div>

                    </div>

                    <div className="grid-overview-item card_overview" onClick={()=>handleOverviewContactUs('dev-query')}>
                        <h6>Dev Query</h6>
                        {/* <hr /> */}
                        <div className='card_hr'></div>
                        {/* <h1>19</h1> */}
                        <div className="row">
                            <div className="col">
                            <ul className='list-unstyled'>
                                {
                                    ContactUsDataLoading===true?(
                                        <>
                                            <Placeholder animation="glow">
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} />  
                                                <Placeholder xs={10} /> 
                                            </Placeholder> 
                                        </>
                                    ):
                                    (
                                        <>
                                            <li>Pending: {statusCountsDevQuery["pending"] || 0}</li>
                                            <li>Follow up: {statusCountsDevQuery["follow up"] || 0}</li>
                                            <li>Rejected: {statusCountsDevQuery["rejected"] || 0}</li>
                                            <li>Closed: {statusCountsDevQuery["closed"] || 0}</li>
                                        </>

                                    )
                                }
                            </ul>
                            </div>
                            <div className="col verticle_center_dashboard" style={{fontSize:'4rem',fontWeight:600}}>
                                {DevQueryNewQueryCount}
                            </div>
                        </div>

                    </div>
                    <div className="grid-overview-item card_overview" >
                        <h6>Facebook Ads Leads</h6>
                        {/* <hr /> */}
                        <div className='card_hr'></div>
                        <div className="row">
                            <div className="col">
                                    Coming soon
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default ContactOverview
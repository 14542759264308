import React, { useCallback, useEffect, useState } from 'react';
import {Link} from 'react-router-dom'

import Cookies from 'js-cookie';
import axios from 'axios';
import BaseURL from '../../../../components/Other/BaseURL';
import $ from 'jquery';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-responsive';
import '../style.css'

function AllTabContents() {
    const [AllBlogs, setAllBlogs] = useState([]);

    const DataRefresh = useCallback(async () => {
        await axios.get(BaseURL + '/get-all-blogs/all', {})
            .then((res) => {
                setAllBlogs(res.data.data);
                console.log(res.data.data);
            })
            .catch((err) => { console.log(err); });
    }, []);

    useEffect(() => {
        DataRefresh();
    }, [DataRefresh]);

    useEffect(() => {
        initializeDataTable();
    }, [AllBlogs]);

    const initializeDataTable = () => {
        if ($.fn.DataTable.isDataTable('#myDataTable')) {
            $('#myDataTable').DataTable().destroy();
        }
        $('#myDataTable').DataTable({
            data: AllBlogs,
            columns: [
                {
                    data: 'title',
                    render: function (data, type, row) {
                        return `<span >`+data + `</span>
                        <br />
                        <div class='action-links' >
                            <a href="./edit-blog/${row.slug}">Edit</a> | <a href="#">Trash</a> | <a href="#">View</a>
                        </div>
                        `;
                    }
                },
                { data: 'author' },
                {
                    data: 'categories',
                    render: function (data, type, row) {
                        return JSON.parse(data).join(', ');
                    }
                },
                {
                    data: 'tags',
                    render: function (data, type, row) {
                        return JSON.parse(data).length > 0 ? JSON.parse(data).join(', ') : '-';
                    }
                },
                // { data: 'status' },
                {
                    data: 'status',
                    render: function (data, type, row) {
                        if(data==="publish"){
                            return `<span class="badge text-bg-success p-1 verticle_center">`+data + `</span>`;
                        }else{
                            return `<span class="badge text-bg-warning p-1 verticle_center">`+data+ `</span>`;
                        }
                        
                    },
                    // createdCell: (td, cellData) => {
                    //   if (cellData === 'admin') {
                    //     $(td).addClass('badge bg-danger');
                    //   } else if (cellData === 'publish') {
                    //     $(td).addClass('badge text-bg-success p-1 verticle_center');
                    //   }
                    // }
                  },
                { data: 'published_on' }
            ],
            responsive: true
        });
    };

    return (
        <>
            <div className='tabContentsContainer mt-3'>
                <table id="myDataTable" className="display fs14 data_table rounded-3" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '30%' }}>Blog Title</th>
                            <th>Author</th>
                            <th>Categories</th>
                            <th>Tags</th>
                            <th>Status</th>
                            <th>Published On</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </>
    );
}

export default AllTabContents;

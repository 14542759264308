import React, { useState,useCallback,useEffect,createContext,useContext } from 'react';
import Template from '../../components/Template/Template';
import JoinWodo from './TabContents/JoinWodo';
import LeadQuery from './TabContents/LeadQuery';
import PrQuery from './TabContents/PrQuery';
import Cookies from 'js-cookie';
import './style.css'
import axios from 'axios'
import BaseURL from '../../components/Other/BaseURL';
import DevQuery from './TabContents/DevQuery';

export const ContactUsStore = createContext();

function ContactUs(props) {
    // const [tabStatus, settabStatus] = useState(Cookies.get('tabContactUsStatus') || 'join-wodo');
    const [ContactUsTabs, setContactUsTabs] = useState(Cookies.get('tabContactUsStatus') || 'join-wodo');

    const [JoinWodoNewQueryCount, setJoinWodoNewQueryCount] = useState(0);
    const [LeadQueryNewQueryCount, setLeadQueryNewQueryCount] = useState(0);
    const [PrQueryNewQueryCount, setPrQueryNewQueryCount] = useState(0);
    const [DevQueryNewQueryCount, setDevQueryNewQueryCount] = useState(0)

    

    const DataRefresh = useCallback(async() => {

        await axios.get(BaseURL+'/contact-us/new-query-count',{})
        .then((res)=>{   
            // console.log(res.data.data);
            setJoinWodoNewQueryCount(res.data.data[0].joinwodo_count);
            setLeadQueryNewQueryCount(res.data.data[0].leadQuery_count);
            setPrQueryNewQueryCount(res.data.data[0].prQuery_count);
            setDevQueryNewQueryCount(res.data.data[0].devQuery_count);
        })
        .catch((err)=>{console.log(err)});
    }, [])

    useEffect(() => {
      DataRefresh();
    }, [DataRefresh]);


    function Tabs(){
        const { setContactUsTabs } = useContext(ContactUsStore);
        const handleTabClick = (status) => {
            setContactUsTabs(status);
            Cookies.set('tabContactUsStatus', status);
        };

        return(
            <>
                <ul className="nav nav-tabs mt-3">
                    <li className="nav-item">
                        <span style={{cursor:'pointer'}} className={`nav-link ${ContactUsTabs==='join-wodo' ? 'active' : ''}`} aria-current="page"  onClick={() => handleTabClick('join-wodo')}>Join WODO ({JoinWodoNewQueryCount})</span>
                    </li>
                    <li className="nav-item">
                        <span style={{cursor:'pointer'}} className={`nav-link ${ContactUsTabs==='lead-query' ? 'active' : ''}`}  onClick={() => handleTabClick('lead-query')}>Leads Query ({LeadQueryNewQueryCount})</span>
                    </li>
                    <li className="nav-item">
                        <span style={{cursor:'pointer'}} className={`nav-link ${ContactUsTabs==='pr-query' ? 'active' : ''}`}  onClick={() => handleTabClick('pr-query')}>Pr Query ({PrQueryNewQueryCount})</span>
                    </li>
                    <li className="nav-item">
                        <span style={{cursor:'pointer'}} className={`nav-link ${ContactUsTabs==='dev-query' ? 'active' : ''}`}  onClick={() => handleTabClick('dev-query')}>Dev Query ({DevQueryNewQueryCount})</span>
                    </li>
                </ul>
            </>
        );
    }

   
    function TabContent() {
        const { ContactUsTabs } = useContext(ContactUsStore);
        return (
            <>
                {ContactUsTabs === 'join-wodo' ? <JoinWodo dataCount={DataRefresh}/> : ContactUsTabs === 'lead-query' ? <LeadQuery dataCount={DataRefresh}/> : ContactUsTabs === 'pr-query' ? <PrQuery dataCount={DataRefresh}/> : ContactUsTabs === 'dev-query' ? <DevQuery dataCount={DataRefresh}/> : ''}
            </>
        );
    }

    function PageContent() {
        return (
            <>
                <div className='fs16'>
                    <Tabs/>
                    <TabContent />
                </div>
            </>
        );
    }

    return (
        <Template currentpage={props.currentpage}>
            <ContactUsStore.Provider value={{ContactUsTabs, setContactUsTabs,JoinWodoNewQueryCount, setJoinWodoNewQueryCount,LeadQueryNewQueryCount, setLeadQueryNewQueryCount,PrQueryNewQueryCount, setPrQueryNewQueryCount}}>
                <PageContent />
            </ContactUsStore.Provider>
        </Template>
    );
}

export default ContactUs;



// import React, { useState, useCallback, useEffect } from 'react';
// import Template from '../../components/Template/Template';
// import axios from 'axios';
// import BaseURL from '../../components/Other/BaseURL';

// function ContactUs(props) {
//     const [ContactUsTabs, setContactUsTabs] = useState('join-wodo');
//     const [joinWodoData, setjoinWodoData] = useState([]);
//     const [LeadQueryData, setLeadQueryData] = useState([]);
//     const [PrQueryData, setPrQueryData] = useState([]);

//     function JoinWodo() {


//         return (
//             <>
//                 <h5>Join WODO</h5>
//                 <table>
//                     <thead>
//                         <tr>
//                             <th>id</th>	
//                             <th>full_name</th>	
//                             <th>email</th>	
//                             <th>phone</th>	
//                             <th>linkedin</th>	
//                             <th>applying_for</th>	
//                             <th>role</th>	
//                             <th>qualification</th>	
//                             <th>experience</th>	
//                             <th>portfolio</th>	
//                             <th>resume</th>	
//                             <th>status</th>	
//                             <th>view_status</th>	
//                             <th>submitted_on</th>	
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {joinWodoData.map((item, index) => (
//                             <tr key={index}>
//                                 <td>#{item.id}</td>
//                                 <td>{item.full_name} </td>
//                                 <td>{item.email} </td>
//                                 <td>{item.phone} </td>
//                                 <td>{item.linkedin} </td>
//                                 <td>{item.applying_for} </td>
//                                 <td>{item.role} </td>
//                                 <td>{item.qualification} </td>
//                                 <td>{item.portfolio} </td>
//                                 <td>{item.resume} </td>
//                                 <td>{item.status} </td>
//                                 <td>{item.view_status} </td>
//                                 <td>{item.submitted_on} </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </>
//         );
//     }

//     function LeadQuery() {
//         return (
//             <>
//                 <h5>Lead Query</h5>
//                 <table>
//                     <thead>
//                         <tr>
//                             <th>SL</th>
//                             <th>Full Name</th>
//                             <th>d</th>
//                         </tr>
//                     </thead>
//                 </table>
//             </>
//         );
//     }

//     function PrQuery() {
//         return (
//             <>
//                 <h5>Pr Query</h5>
//             </>
//         );
//     }

//     function TabContent() {
//         return (
//             <>
//                 {ContactUsTabs === 'join-wodo' ? <JoinWodo /> : ContactUsTabs === 'lead-query' ? <LeadQuery /> : ContactUsTabs === 'pr-query' ? <PrQuery /> : ''}
//             </>
//         );
//     }

//     function PageContent() {
//         return (
//             <>
//                 <div className='fs16'>
//                     <button onClick={() => { setContactUsTabs('join-wodo') }}>
//                         Join WODO
//                     </button>
//                     <button onClick={() => { setContactUsTabs('lead-query') }}>
//                         Lead Query
//                     </button>
//                     <button onClick={() => { setContactUsTabs('pr-query') }}>
//                         Pr Query
//                     </button>
//                 </div>
//                 <TabContent />
//             </>
//         );
//     }

//     return (
//         <Template currentpage={props.currentpage}>
//             <PageContent />
//         </Template>
//     );
// }

// export default ContactUs;


// import React,{useState,useCallback,useEffect} from 'react'
// import Template from '../../components/Template/Template';
// import axios from 'axios'
// import BaseURL from '../../components/Other/BaseURL';


// function ContactUs(props) {

//     const [ContactUsTabs, setContactUsTabs] = useState('join-wodo');

//     const [joinWodoData, setjoinWodoData] = useState([])



//     function  JoinWodo(){



//         const DataRefresh = useCallback(async() => {

//             await axios.get(BaseURL + '/contact-us/join-wodo',{})
//             .then((res)=>{   
//                 setjoinWodoData(res.data.data);
//                 // console.log(res.data.data);
//             })
//             .catch((err)=>{console.log(err)})
    
//         }, [])
    
    
//         useEffect(() => {
//             DataRefresh();
//         }, [DataRefresh]);

//         return(
//             <>
//                 <h5>Join WODO </h5>
//                 {
//                     joinWodoData.map((item,index) => (
//                         <tr key={index}> 
//                             <td>#{item.id}</td>
//                             <td>{item.full_name}</td>
//                         </tr>
//                     ))
//                 }
//             </>
//         );
//     }

//     function LeadQuery(){
//         return(
//             <>
//                 <h5>Lead Query</h5>
//                 <table>
//                     <thead>
//                         <td>SL</td>
//                         <td>Full Name</td>
//                         <td>d</td>                        
//                     </thead>
//                 </table>
//             </>
//         );
//     }

//     function PrQuery(){
//         return(
//             <>
//                 <h5>Pr Query</h5>
//             </>
//         );
//     }


//     function TabContent(){

//         return(
//         <>
//             {ContactUsTabs==='join-wodo'?<JoinWodo/>:ContactUsTabs==='lead-query'?<LeadQuery/>:ContactUsTabs==='pr-query'?<PrQuery/>:''}
//         </>);
//     }

//     function PageContent(){
//         return(
//             <>
//                 <div className='fs16'>
//                     <button onClick={()=>{setContactUsTabs('join-wodo')}}>
//                         Join WODO
//                     </button>

//                     <button onClick={()=>{setContactUsTabs('lead-query')}}>
//                         Lead Query
//                     </button>

//                     <button onClick={()=>{setContactUsTabs('pr-query')}}>
//                         Pr Query
//                     </button>
//                 </div>

//                 <TabContent/>

//             </>
//         );
//     }

//     return (
//         <Template currentpage={props.currentpage}>
//             <PageContent/>
//         </Template>
//     )
// }

// export default ContactUs
import React from 'react'
import Template2 from '../../components/Template/Template';


function Template(props) {
    function PageContent(){
        return(
            <>
                <div className='fs16'>Template, Write here</div>
            </>
        );
    }



    return (
        <>
            <Template2 currentpage={props.currentpage}>
                <PageContent/>
            </Template2>
        </>
    )
}

export default Template
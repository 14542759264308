import React,{useState,useEffect,useRef,useCallback} from 'react'
import Template from '../../components/Template/Template';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';
import PostAddBlog from './BlogComponents/PostAddBlog';



function AddBlog(props) {
    const [editorData, setEditorData] = useState('');
    const selectCategoriesRef = useRef(null);
    const selectTagsRef = useRef(null);

    function Header(){
        return(
            <>
                <div className='fs16'>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <Link to='/blogs' className='pt-1 pe-4'>
                                <i className="fas fa-caret-left" style={{fontSize:20,color:'#000'}}></i>
                            </Link>
                            <h4>New Blog</h4>
                        </div>

                        <div style={{display:'flex',flexDirection:'row',gap:10}}>
                            <button className='btn btn-sm'>Save Draft</button>
                            <button className='btn btn-sm'>view</button>
                            <button className='btn btn-sm btn-yellow'>Publish</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function AddArticle(props){



        return(
            <>
                <div className="row">
                    <div className="col-md">

                        <input type="text" defaultValue={props.blogtitle} onChange={props.setBlogtitle} placeholder='Add Title' style={{background:'none',border:'none',fontSize:29,width:'100%'}} />

                        <div className="row">

                            <div className="col">
                                <CKeditorBlog/>
                            </div>

                            <div className="col-md-2">
                                <PostAddBlog blogtitle={props.blogtitle} setBlogtitle={props.setBlogtitle}/>
                            </div>
                        </div>

                    </div>

                </div>
            </>
        );
    }


    function  CKeditorBlog(){

        const editorConfiguration = {
            // toolbar: [
            //   'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'
            // ]
          };

          
        return(
            <>
                <div>
                    <CKEditor
                        editor={ClassicEditor}
                        data="<p>Hello from CKEditor 5!</p>"
                        config={editorConfiguration}
                        onReady={(editor) => {
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            // setEditorData(data);
                            console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}

                    />
                    <div>
                        {/* <h3>Content:</h3> */}
                        {/* <div dangerouslySetInnerHTML={{ __html: editorData }} /> */}
                    </div>
                </div>
            </>
        );
    }


    function ModalFeaturedImage(props){

        const [tabFeature, settabFeature] = useState('upload')

        const handleTabFeatureClick = (status) => {
            settabFeature(status);
            Cookies.set('tabStatus', status);

        };

        return(
            <>
                <div className="modal fade" id="FeaturedImageModal" data-bs-backdrop="static" tabindex="9" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                        <div className="modal-content">
                        <div className="modal-header" style={{border:0}}>
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Featured image</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ul className="nav nav-tabs mt-3">
                                <li className="nav-item">
                                    <span style={{cursor:'pointer'}} className={`nav-link ${tabFeature==='upload' ? 'active_feature_tab ' : ''}`} aria-current="page"  onClick={() => handleTabFeatureClick('upload')}>Upload</span>
                                </li>
                                <li className="nav-item">
                                    <span style={{cursor:'pointer'}} className={`nav-link ${tabFeature==='media' ? 'active_feature_tab' : ''}`}  onClick={() => handleTabFeatureClick('media')}>Media files</span>
                                </li>
                            </ul>

                            <div style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,50%)'}}>
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <h6>Drop files to upload</h6>
                                    <h6>or</h6>    
                                    <input type="file" name="" placeholder='Select Files' id="" />
                                    <p>Maximum upload file size: 1 GB.</p>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }



    function PageContent(){
        const [BlogTitle, setBlogTitle] = useState('')
        
        const setBlogTitlehandleInputChange = useCallback((event) => {
            console.log('Input changed:', event.target.value);
            setBlogTitle(event.target.value);
        });

        return(
            <>
                <div className="container-fluid">
                    <Header/>
                    <AddArticle blogtitle={BlogTitle} setBlogtitle={setBlogTitlehandleInputChange}/>
                </div>

            </>
        );
    }

    return (
        <>
            <ModalFeaturedImage/>
            <Template currentpage={props.currentpage}>
                <PageContent/>
            </Template>
        </>
    )
}

export default AddBlog
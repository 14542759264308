import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import $ from 'jquery';
import './style.css';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import BaseURL from '../../../components/Other/BaseURL';
import Template from '../../../components/Template/Template';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function PrQuery(props) {
    // const data = useMemo(() => props.prData, [props.prData]);
    const [data, setData] = useState({});
    const [ViewModalshow, setViewModalShow] = useState(false);
    const [ViewModalData, setViewModalData] = useState([]);

    const handleViewModalClose = useCallback(() => setViewModalShow(false), []);
    const handleViewModalShow = useCallback(() => setViewModalShow(true), []);

    useEffect(() => {
        // joinWodoFetchData();
        // LeadFetchData();
        prFetchData();
    }, []);

    const prFetchData = async () => {
        try {
            const response = await axios.get(`${BaseURL}/contact-us/pr-query`);
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        initializeDataTable();
    }, [data,ViewModalshow]);

    const initializeDataTable = useCallback(() => {
        if ($.fn.DataTable.isDataTable('#prTable')) {
            $('#prTable').DataTable().destroy();
        }
        const table = $('#prTable').DataTable({
            data: data,
            order: [[0, 'desc']],
            bStateSave:true,
            columns: [

                {data:'submitted_on'},
                {data:'name'},
                {data:'company_name'},
                {data:'email'},
                {data:'phone'},
                {data:'services'},
                { 
                    data: 'status',
                    render: function (data, type, row) {
                        if (data === 'pending') {
                            return `<span class='badge text-bg-warning nav-link'>Pending</span>`;
                        } else if (data === 'follow up') {
                            return `<span class='badge text-bg-info nav-link'>Follow Up</span>`;
                        }else if(data === 'rejected') {
                            return `<span class='badge text-bg-danger nav-link'>Rejected</span>`;
                        }else if(data === 'closed') {
                            return `<span class='badge text-bg-secondary nav-link'>Closed</span>`;
                        }else{
                            return `<span class='badge text-bg-primary nav-link'>${data}</span>`;
                        }
                        
                    }
                },
                // {data:'view_status'},
                // {data:'submitted_on'},

                // { data: 'id',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     }
                // },
                // {
                //     data: 'full_name',
                //     render: function (data, type, row) {
                //         return `<span >`+data + `</span>
                //         `;
                //         // ${row.view_status==0?"<span class='badge  text-bg-success'>new</span>":''}
                //     },
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     }
                // },
                // { 
                //     data: 'email',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     }
                // },
                // { data: 'phone',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     } 
                // },
                // { data: 'applying_for',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     } 
                // },
                // { data: 'role',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     } 
                // },
                // { data: 'qualification',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     } 
                // },
                // { data: 'experience',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     } 
                // },
                // {
                //     data: 'resume',
                //     render: function (data, type, row) {
                //         return `<a class='badge text-bg-warning nav-link' href='${BaseURL}${data}'> FILE </a>`;
                //     }
                // },
                // {
                //     data: 'status',
                //     render: function (data, type, row) {
                //         return `
                //         <select class='form-control' value='${data}'>
                //             <option value='pending'>Pending</option>
                //             <option value='follow up'>Follow up</option>
                //             <option value='rejected'>Rejected</option>
                //             <option value='closed'>Closed</option>
                //         </select>`;
                //     }
                // },
                // { data: 'submitted_on' }
            ],
            responsive: true
        });

        $('#prTable tbody').on('click', 'tr', function (event) {
            event.preventDefault();
            // const rowData = table.row($(this).parents('tr')).data();
            const rowData = table.row(this).data();
            setViewModalData(rowData);
            handleViewModalShow();


        });
    }, [data, handleViewModalShow,ViewModalData]);

    function PageContent() {
        return (
            <div className="container-fluid">
                <table id="prTable" className="display tab_tables" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Submitted</th>
                            <th>Name</th>
                            <th>company_name</th>
                            <th>email</th>
                            <th>phone</th>
                            <th>services</th>
                            <th>status</th>
                            {/* <th>view_status</th> */}
                        </tr>
                    </thead>
                </table>
            </div>
        );
    }

    function ViewModal() {
        const [status, setStatus] = useState(ViewModalData.status);
        const [Updateid, setUpdateId] = useState(ViewModalData.id);

        const handleChange = (e) => {
          setStatus(e.target.value);
        };

        const handleSubmit = async () => {
            try {
                const tab='prQuery'; 
                const response = await axios.put(`${BaseURL}/contact-us/set-status`, { tab,status,Updateid });
                console.log('Response:', response.data);
                handleViewModalClose();
                props.dataCount();
                prFetchData();
            } catch (error) {
                console.error('Error submitting status:', error);
            }
        };
        return (
            <Modal show={ViewModalshow} backdrop="static" keyboard={false} onHide={handleViewModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Join Wodo #{ViewModalData.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table table-hover'>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{ViewModalData.name}</td>
                            </tr>
                            <tr>
                                <th>Company Name</th>
                                <td>{ViewModalData.company_name}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{ViewModalData.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{ViewModalData.phone}</td>
                            </tr>
                            <tr>
                                <th>Services</th>
                                <td>{ViewModalData.services}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>
                                    <select name="" id="" className='form-select'  onChange={handleChange} value={status}>
                                        <option value='pending'>Pending</option>
                                        <option value='follow up'>Follow up</option>
                                        <option value='rejected'>Rejected</option>
                                        <option value='closed'>Closed</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Submitted</th>
                                <td>{ViewModalData.submitted_on}</td>
                            </tr>

                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' variant="secondary" onClick={handleViewModalClose}>
                        Close
                    </Button>
                    <Button size='sm' variant="warning" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div>
            <ViewModal />
            <PageContent />
        </div>
    );
}

export default PrQuery;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import $ from 'jquery';
// import './style.css'
// import DataTable from 'datatables.net-bs5';
// import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
// import BaseURL from '../../components/Other/BaseURL';
// import Template from '../../components/Template/Template';

// function PrQuery() {
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         fetchData2();
//     }, []);

//     const fetchData2 = async () => {
//         try {
//             const response = await axios.get(`${BaseURL}/contact-us/pr-query`);
//             setData(response.data.data);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };


//     useEffect(() => {
//         initializeDataTable();
//     }, [data]);

//     const initializeDataTable = () => {
//         if ($.fn.DataTable.isDataTable('#PrQueryTable')) {
//             $('#PrQueryTable').DataTable().destroy();
//         }
//         $('#PrQueryTable').DataTable({
//             data: data,
//             columns: [	
//                 { data: 'id' },
//                 { data: 'name' },
//                 { data: 'company_name' },
//                 { data: 'email' },
//                 { data: 'phone' },
//                 { data: 'services' },
//                 { data: 'status' },
//                 // { data: 'view_status' },
//                 { data: 'submitted_on' }
//             ],
//             responsive: true
//         });

//     };

//     function PageContent(){
//         return(
//             <>
//                 <div className="container-fluid">
//                     <table id="PrQueryTable" className="display tab_tables" style={{ width: '100%' }}>
//                         <thead>
//                             <tr>
//                                 <th>id</th>
//                                 <th>Name</th>
//                                 <th>company_name</th>
//                                 <th>Email</th>
//                                 <th>phone</th>
//                                 <th>services</th>
//                                 <th>status</th>
//                                 {/* <th>view_status</th> */}
//                                 <th>Submitted</th>
//                             </tr>
//                         </thead>
//                     </table>
//                 </div>
//             </>
//         );
//     }

//     return (
//         <div>

//             {/* <Template currentpage={props.currentpage}> */}
//             <PageContent />

//             {/* </Template> */}
//         </div>
//     )
// }

// export default PrQuery
import React from 'react'
import {Link} from 'react-router-dom'

function BlogsOverview() {
  return (
    <>
        <div className='mt-3'>
            <h5>Blogs</h5>
            <div className="grid-overview-container">
                <div className="grid-overview-item card_overview" >
                    <h6>Blogs Details</h6>
                    {/* <hr /> */}
                    <div className='card_hr'></div>
                    <div className="row">
                        <div className="col">
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>Published</td>
                                        <th>0</th>
                                    </tr>
                                    <tr>
                                        <td>Drafted</td>
                                        <th>0</th>
                                    </tr>
                                    <tr>
                                        <td>Private</td>
                                        <th>0</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div className='grid-overview-item wide card_overview'>
                    <h6>Top viewed blogs</h6>
                    <div className='card_hr'></div>
                    <div className="row">
                        <div className="col">
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>
                                            10 Design Trends That Will Dominate in 2023: Insights from a Leading Digital Marketing Agency in Dubai
                                        </td>
                                        <th>0</th>
                                    </tr>
                                    <tr>
                                        <td>AI for Business Analytics 2024: Unlocking New Dimensions of Data Intelligence </td>
                                        <th>0</th>
                                    </tr>
                                    <tr>
                                        <td>Building a Winning Content Marketing Strategy for B2B Companies</td>
                                        <th>0</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="grid-overview-item card_overview" >
                    <h6>Top viewed category</h6>
                    {/* <hr /> */}
                    <div className='card_hr'></div>
                    <div className="row">
                        <div className="col">
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>Design Tips</td>
                                        <th>0</th>
                                    </tr>
                                    <tr>
                                        <td>Marketing Guide</td>
                                        <th>0</th>
                                    </tr>
                                    <tr>
                                        <td>Digital Dairy</td>
                                        <th>0</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BlogsOverview
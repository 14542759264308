import React from 'react'
import Template from '../../components/Template/Template';
import './style.css'
import ContactUsOverview from './ContactUsOverview/ContactUsOverview';
import BlogsOverview from './BlogsOverview/BlogsOverview';

function Dashboard(props) {


    function PageContent(){
        return(
            <>
                <div className='fs16'>
                    <div className="container-fluid">
                        <ContactUsOverview/>
                        <BlogsOverview/>
                    </div>
                </div>
            </>
        );
    }



    return (
        <>
            <Template currentpage={props.currentpage}>
                <PageContent/>
            </Template>
        </>
    )
}

export default Dashboard
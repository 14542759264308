import { useParams } from 'react-router-dom';
import React,{useState,useEffect,useRef,useCallback} from 'react'
import Template from '../../components/Template/Template';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';
import PostAddBlog from './BlogComponents/PostAddBlog';
import axios from 'axios';
import BaseURL from '../../components/Other/BaseURL';

import folderIcon from '../../assets/folder.png';  // Add a folder icon image to your project
import fileIcon from '../../assets/image.png';  // Add a default file icon image to your project



// const { slug } = useParams();

// return (
//   <div>
//     EditBlog
//     <p>Editing blog with slug: {slug}</p>  
//   </div>
// )
function EditBlog(props) {
  const { slug } = useParams();
  const [editorData, setEditorData] = useState('');
  const selectCategoriesRef = useRef(null);
  const selectTagsRef = useRef(null);

  const [BlogTitle, setBlogTitle] = useState('')
  const [BlogContent, setBlogContent] = useState('')
  const [BlogCategory, setBlogCategory] = useState('')
  const [BlogTags, setBlogTags] = useState('')
  const [BlogAuthor, setBlogAuthor] = useState('')
  // const [BlogImage, setBlogImage] = useState('')
  const [BlogDescription, setBlogDescription] = useState('')



  const DataEditRefresh = useCallback(async() => {

    await axios.get(BaseURL+'/get-all-blogs/'+slug,{})
      .then((res)=>{   
        // setAllBlogs(res.data.data);
        console.log(res.data.data);
        setBlogTitle(res.data.data[0].title)
        setBlogContent(res.data.data[0].body)
        setBlogDescription(res.data.data[0].description)
        setBlogCategory(res.data.data[0].categories)
        setBlogAuthor(res.data.data[0].author)
        // setAllCount(res.data.data.length);
      })
      .catch((err)=>{console.log(err)})
    }, [])

useEffect(() => {
  DataEditRefresh();
}, [DataEditRefresh]);

  function Header(){
      return(
          <>
              <div className='fs16'>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <div style={{display:'flex',flexDirection:'row'}}>
                          <Link to='/blogs' className='pt-1 pe-4'>
                              <i className="fas fa-caret-left" style={{fontSize:20,color:'#000'}}></i>
                          </Link>
                          <h4>Edit Blog</h4>
                      </div>

                      <div style={{display:'flex',flexDirection:'row',gap:10}}>
                          <button className='btn btn-sm'>Save Draft</button>
                          <button className='btn btn-sm'>view</button>
                          <button className='btn btn-sm btn-yellow'>Publish</button>
                      </div>
                  </div>
              </div>
          </>
      );
  }

  function AddArticle(props){



      return(
          <>
              <div className="row">
                  <div className="col-md">

                      <input type="text" placeholder='Add Title' value={BlogTitle} id='blog_title' style={{background:'none',border:'none',fontSize:29,width:'100%'}} />

                      <div className="row">

                          <div className="col">
                              <CKeditorBlog/>
                          </div>

                          <div className="col-md-2">
                              <PostAddBlog blogtitle={BlogTitle} description={BlogDescription} categories={BlogCategory} author={BlogAuthor}/>
                          </div>
                      </div>

                  </div>

              </div>
          </>
      );
  }


  function  CKeditorBlog(){

      const editorConfiguration = {
          // toolbar: [
          //   'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'
          // ]
        };

        
      return(
          <>
              <div>
                  <CKEditor
                      editor={ClassicEditor}
                      data={BlogContent}
                      config={editorConfiguration}
                      onReady={(editor) => {
                          console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                          const data = editor.getData();
                          // setBlogContent(data);
                          // console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                      }}
                  />
                  <div>
                      {/* <h3>Content:</h3> */}
                      {/* <div dangerouslySetInnerHTML={{ __html: editorData }} /> */}
                  </div>
              </div>
          </>
      );
  }


  function ModalFeaturedImage(props){

      const [tabFeature, settabFeature] = useState('upload')


      const handleTabFeatureClick = (status) => {
          settabFeature(status);
          Cookies.set('tabStatus', status);

      };

      const [files, setFiles] = useState([]);
      const [currentPath, setCurrentPath] = useState('');
      const [selectedFile, setSelectedFile] = useState(null);

      useEffect(() => {
          axios.get(`http://localhost:3001/media-files/${currentPath}`)
              .then(response => {
                  setFiles(response.data);
              })
              .catch(error => {
                  console.error('Error fetching media files:', error);
              });
      }, [currentPath]);

      const handleFolderClick = (folderPath) => {
          setCurrentPath(folderPath);
      };

      const handleBackClick = () => {
          const parentPath = currentPath.split('/').slice(0, -1).join('/');
          setCurrentPath(parentPath);
      };

      const handleFileClick = (file) => {
        setSelectedFile(file);
    };


    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await axios.post(BaseURL+'/upload-media-file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setUploadStatus(response.data);
      } catch (error) {
        setUploadStatus('Upload failed.');
        console.error('Error:', error);
      }
    };


      return(
          <>
              <div className="modal fade" id="FeaturedImageModal" data-bs-backdrop="static" tabindex="9" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                      <div className="modal-content">
                      <div className="modal-header" style={{border:0}}>
                          <h1 className="modal-title fs-5" id="exampleModalLabel">Featured image</h1>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <ul className="nav nav-tabs mt-3">
                              <li className="nav-item">
                                  <span style={{cursor:'pointer'}} className={`nav-link ${tabFeature==='upload' ? 'active_feature_tab ' : ''}`} aria-current="page"  onClick={() => handleTabFeatureClick('upload')}>Upload</span>
                              </li>
                              <li className="nav-item">
                                  <span style={{cursor:'pointer'}} className={`nav-link ${tabFeature==='media' ? 'active_feature_tab' : ''}`}  onClick={() => handleTabFeatureClick('media')}>Media files</span>
                              </li>
                          </ul>

                          {/* upload */}
                          <div  style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,50%)',display:''+tabFeature==='upload' ? 'block' : 'none'+''}}>
                              <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                  <h6>Drop files to upload</h6>
                                  <h6>or</h6>    
                                  <input type="file" name="" placeholder='Select Files' onChange={handleFileChange} id="" />
                                  <p>Maximum upload file size: 1 GB.</p>
                              </div>
                          </div>

                          {/* media */}
                          <div  style={{display:''+tabFeature==='media' ? 'block' : 'none'+''}}>
                            <div className="row">
                              <div className="col">
                              <div>
                                    {/* <h5>Media Files</h5> */}
                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                        {currentPath && (
                                            <span className="btn btn-sm" onClick={handleBackClick}>Back</span>
                                        )}
                                        {"/" + currentPath}
                                    </div>
                                    <div style={{height: '60vh', overflowY: 'scroll'}}>
                                        <div className='grid-container'>
                                            {files.map((file, index) => (
                                                <div 
                                                    className='grid-item' 
                                                    key={index} 
                                                    onClick={() => file.type === 'folder' ? handleFolderClick(file.path) : handleFileClick(file)} 
                                                    style={{cursor: file.type === 'folder' ? 'pointer' : 'default'}}
                                                >
                                                    {file.type === 'folder' ? (
                                                        <img src={folderIcon} alt="Folder" style={{maxWidth: '100%', maxHeight: '80%'}} />
                                                    ) : (
                                                        <img src={BaseURL + "/media/" + file.path} alt="File" style={{maxWidth: '100%', maxHeight: '80%'}} onError={(e) => { e.target.src = fileIcon; }} />
                                                    )}
                                                    <p className='fs12'>{file.name} </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div className="col">
                                <div style={{height: '80vh', overflowY: 'scroll'}}>
                                {selectedFile && (
                                      <>
                                          <p>File: {selectedFile.name}</p>
                                          <p>URL: {BaseURL + "/media/" + selectedFile.path}</p>
                                          {selectedFile.type === 'file' && (
                                              <img src={BaseURL + "/media/" + selectedFile.path} alt={selectedFile.name} style={{maxWidth:'80%'}} />
                                          )}
                                      </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                      </div>
                      <div className="modal-footer">

                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                          <button type="button" className="btn btn-primary" onClick={handleFileUpload}>Upload</button>
                      </div>
                      </div>
                  </div>
              </div>
          </>
      );
  }



  function PageContent(){


      return(
          <>
              <div className="container-fluid">
                  <Header/>
                  <AddArticle/>
              </div>

          </>
      );
  }

  return (
      <>
          <ModalFeaturedImage/>
          <Template currentpage={props.currentpage}>
              <PageContent/>
          </Template>
      </>
  )
}

export default EditBlog
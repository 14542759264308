import React,{useState,useEffect,useCallback} from 'react';
import './style.css';
import {Link} from 'react-router-dom'
import Template from '../../components/Template/Template';
import Cookies from 'js-cookie';
import axios from 'axios';
import BaseURL from '../../components/Other/BaseURL';
import AllTabContents from './BlogComponents/TabContents/AllTabContents';
import PublishedTabContents from './BlogComponents/TabContents/PublishedTabContents';
import DraftsTabContents from './BlogComponents/TabContents/DraftsTabContents';


function Blogs(props) {
    const [tabStatus, settabStatus] = useState(Cookies.get('tabStatus') || 'all');

    function Tabs(){
        const [AllCount, setAllCount] = useState(0)
        const [PublishedCount, setPublishedCount] = useState(0)
        const [DraftsCount, setDraftsCount] = useState(0)

        const DataRefresh = useCallback(async() => {

            await axios.get(BaseURL+'/get-all-blogs/all',{})
            .then((res)=>{   
                // setAllBlogs(res.data.data);
                // console.log(res.data.data.length);
                setAllCount(res.data.data.length);

                let published = 0;
                let drafts = 0;
          
                res.data.data.forEach(obj => {
                  if (obj.status === 'publish') {
                    published++;
                  } else if (obj.status === 'draft') {
                    drafts++;
                  }
                });

                setPublishedCount(published);
                setDraftsCount(drafts);



            })
            .catch((err)=>{console.log(err)})
      
        }, [])

        useEffect(() => {
          DataRefresh();
        }, [DataRefresh]);
        

        const handleTabClick = (status) => {
            settabStatus(status);
            Cookies.set('tabStatus', status);

        };

        return(
            <>
                <ul className="nav nav-tabs mt-3">
                    <li className="nav-item">
                        <span style={{cursor:'pointer'}} className={`nav-link ${tabStatus==='all' ? 'active' : ''}`} aria-current="page"  onClick={() => handleTabClick('all')}>All ({AllCount})</span>
                    </li>
                    <li className="nav-item">
                        <span style={{cursor:'pointer'}} className={`nav-link ${tabStatus==='published' ? 'active' : ''}`}  onClick={() => handleTabClick('published')}>Published ({PublishedCount})</span>
                    </li>
                    <li className="nav-item">
                        <span style={{cursor:'pointer'}} className={`nav-link ${tabStatus==='drafts' ? 'active' : ''}`}  onClick={() => handleTabClick('drafts')}>Drafts ({DraftsCount})</span>
                    </li>
                </ul>
            </>
        );
    }

    // function ActiveTabContents(){
    //     const [AllBlogs, setAllBlogs] = useState([])

    //     const DataRefresh = useCallback(async() => {

    //         await axios.get(BaseURL+'/get-all-blogs',{})
    //         .then((res)=>{   
    //             setAllBlogs(res.data.data);
    //             console.log(res.data.data);
    //         })
    //         .catch((err)=>{console.log(err)})
      
    //       }, [])
      
      
    //     useEffect(() => {
    //       DataRefresh();
    //     }, [DataRefresh]);

    //     return(
    //         <>
    //             <div className='tabContentsContainer mt-3'>
    //                 <table className='tab_tables fs14'>
    //                     <tr>
    //                         <th style={{width:'30%'}}>Blog Title</th>
    //                         <th>Author</th>
    //                         <th>Categories</th>
    //                         <th>Tags</th>
    //                         <th>status</th>
    //                         <th>published on</th>
    //                     </tr>

    //                     {
    //                         AllBlogs.map((item) => (
    //                             <tr className='data_tr' key={item.id}>
    //                                 <td>{item.title}</td>
    //                                 <td>{item.author}</td>
    //                                 <td>{JSON.parse(item.categories).join(', ')}</td>
    //                                 <td>{JSON.parse(item.tags).length>0?JSON.parse(item.tags).join(', '):'-'}</td>
    //                                 <td>{item.status}</td>
    //                                 <td>{item.published_on}</td>
    //                             </tr>
    //                         ))
    //                     }

    //                     {/* <tr className='data_tr'>
    //                         <td>
    //                             2Marketing on a Budget: Effective Strategies for Startups and Small Businesses
    //                             <br />
    //                             <a href="">Edit</a> | <a href="">Trash</a> | <a href="">View</a>
    //                         </td>
    //                         <td>
    //                             dummy test
    //                         </td>
    //                         <td>
    //                             Marketing Guide
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             Published <br />
    //                             2024/04/20 at 12:13 pm
    //                         </td>
    //                         <td>
    //                             Keyword: Strategies for Startups
    //                         </td>
    //                     </tr> */}

    //                 </table>
    //             </div>
    //         </>
    //     );
    // }

    // function PublishedTabContents(){
    //     return(
    //         <>
    //             <div className='tabContentsContainer mt-3'>
    //                 <table className='tab_tables fs14'>
    //                     <tr>
    //                         <th>
    //                             <input type="checkbox" name="" id="" />
    //                         </th>
    //                         <th style={{width:'30%'}}>
    //                             Blog Title
    //                         </th>
    //                         <th>
    //                             Author
    //                         </th>
    //                         <th>
    //                             Categories
    //                         </th>
    //                         <th>
    //                             Tags
    //                         </th>
    //                         <th>
    //                             Comments
    //                         </th>
    //                         <th>
    //                             Date
    //                         </th>
    //                         <th>
    //                             SEO Details
    //                         </th>

    //                     </tr>

    //                     <tr className='data_tr'>
    //                         <td>
    //                             <input type="checkbox" name="" id="" />
    //                         </td>
    //                         <td>
    //                             Marketing on a Budget: Effective Strategies for Startups and Small Businesses
    //                             <br />
    //                             Edit | Trash | View
    //                         </td>
    //                         <td>
    //                             dummy test
    //                         </td>
    //                         <td>
    //                             Marketing Guide
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             Published <br />
    //                             2024/04/20 at 12:13 pm
    //                         </td>
    //                         <td>
    //                             Keyword: Strategies for Startups
    //                         </td>
    //                     </tr>

    //                     <tr className='data_tr'>
    //                         <td>
    //                             <input type="checkbox" name="" id="" />
    //                         </td>
    //                         <td>
    //                             Marketing on a Budget: Effective Strategies for Startups and Small Businesses
    //                             <br />
    //                             Edit | Trash | View
    //                         </td>
    //                         <td>
    //                             dummy test
    //                         </td>
    //                         <td>
    //                             Marketing Guide
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             Published <br />
    //                             2024/04/20 at 12:13 pm
    //                         </td>
    //                         <td>
    //                             Keyword: Strategies for Startups
    //                         </td>
    //                     </tr>
    //                 </table>
    //             </div>
    //         </>
    //     );
    // }

    // function DraftsTabContents(){
    //     return(
    //         <>
    //             <div className='tabContentsContainer mt-3'>
    //                 <table className='tab_tables fs14'>
    //                     <tr>
    //                         <th>
    //                             <input type="checkbox" name="" id="" />
    //                         </th>
    //                         <th style={{width:'30%'}}>
    //                             Blog Title
    //                         </th>
    //                         <th>
    //                             Author
    //                         </th>
    //                         <th>
    //                             Categories
    //                         </th>
    //                         <th>
    //                             Tags
    //                         </th>
    //                         <th>
    //                             Comments
    //                         </th>
    //                         <th>
    //                             Date
    //                         </th>
    //                         <th>
    //                             SEO Details
    //                         </th>

    //                     </tr>

    //                     <tr className='data_tr'>
    //                         <td>
    //                             <input type="checkbox" name="" id="" />
    //                         </td>
    //                         <td>
    //                             Marketing on a Budget: Effective Strategies for Startups and Small Businesses
    //                             <br />
    //                             Edit | Trash | View
    //                         </td>
    //                         <td>
    //                             dummy test
    //                         </td>
    //                         <td>
    //                             Marketing Guide
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             -
    //                         </td>
    //                         <td>
    //                             Published <br />
    //                             2024/04/20 at 12:13 pm
    //                         </td>
    //                         <td>
    //                             Keyword: Strategies for Startups
    //                         </td>
    //                     </tr>
    //                 </table>
    //             </div>
    //         </>
    //     );
    // }

    function TabContents(){
        return(
            <>
                {
                    tabStatus==='all'? <AllTabContents/> :
                    tabStatus==='published'? <PublishedTabContents/>: 
                    tabStatus==='drafts' ? <DraftsTabContents/>:''
                }
            </>
        );
    }

    function PageContent(){
        return(
            <>
                <div className='container-fluid fs16' >
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <h4>Blogs</h4>
                        <Link className='btn btn-sm btn-yellow' to='/add-blog'>New blog</Link>
                    </div>
                    <Tabs/>
                    <TabContents/>
                </div>
            </>
        );
    }

    return (
        <>
            <Template currentpage={props.currentpage}>
                <PageContent/>
            </Template>
        </>
    )
}

export default Blogs
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import $ from 'jquery';
import './style.css';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import BaseURL from '../../../components/Other/BaseURL';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function JoinWodo(props) {
    const [data, setData] = useState({});
    const [ViewModalshow, setViewModalShow] = useState(false);
    const [ViewModalData, setViewModalData] = useState([]);

    const handleViewModalClose = useCallback(() => setViewModalShow(false), []);
    const handleViewModalShow = useCallback(() => setViewModalShow(true), []);

    useEffect(() => {
        joinWodoFetchData();
    }, []);

    const joinWodoFetchData = async () => {
        try {
            const response = await axios.get(`${BaseURL}/contact-us/join-wodo`);
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        initializeDataTable();
    }, [data,ViewModalshow]);

    const initializeDataTable = useCallback(() => {
        if ($.fn.DataTable.isDataTable('#joinWodoTable')) {
            $('#joinWodoTable').DataTable().destroy();
        }
        const table = $('#joinWodoTable').DataTable({
            
            data: data,
            bStateSave:true,
            order: [[0, 'desc']],
            createdRow: (row, data, dataIndex) => {
                if(data.view_status==0){
                    $(row).addClass('new-query'); // Replace 'yourClassName' with the class you want to add
                }
            },
            columns: [
                { data: 'submitted_on',
                    createdCell: (td, cellData, rowData, row, col) => {
                        $(td).addClass('joinwodoviewbtn');
                    }
                },
                {data:'id'},
                {
                    data: 'full_name',
                    render: function (data, type, row) {
                        return `<span >`+data + `</span>
                        `;
                        // ${row.view_status==0?"<span class='badge  text-bg-success'>new</span>":''}
                    },
                    createdCell: (td, cellData, rowData, row, col) => {
                        $(td).addClass('joinwodoviewbtn');
                    }
                },
                { 
                    data: 'email',
                    createdCell: (td, cellData, rowData, row, col) => {
                        $(td).addClass('joinwodoviewbtn');
                    }
                },
                { data: 'phone',
                    createdCell: (td, cellData, rowData, row, col) => {
                        $(td).addClass('joinwodoviewbtn');
                    } 
                },
                { data: 'applying_for',
                    createdCell: (td, cellData, rowData, row, col) => {
                        $(td).addClass('joinwodoviewbtn');
                    } 
                },
                { data: 'role',
                    createdCell: (td, cellData, rowData, row, col) => {
                        $(td).addClass('joinwodoviewbtn');
                    } 
                },
                // { data: 'qualification',
                //     createdCell: (td, cellData, rowData, row, col) => {
                //         $(td).addClass('joinwodoviewbtn');
                //     } 
                // },
                { data: 'experience',
                    createdCell: (td, cellData, rowData, row, col) => {
                        $(td).addClass('joinwodoviewbtn');
                    } 
                },
                {
                    data: 'resume',
                    render: function (data, type, row) {
                        return `<a class='badge text-bg-secondary nav-link' href='${BaseURL}${data}'> FILE </a>`;
                    }
                },
                { 
                    data: 'status',
                    render: function (data, type, row) {
                        if (data === 'pending') {
                            return `<span class='badge text-bg-warning nav-link'>Pending</span>`;
                        } else if (data === 'follow up') {
                            return `<span class='badge text-bg-info nav-link'>Follow Up</span>`;
                        }else if(data === 'rejected') {
                            return `<span class='badge text-bg-danger nav-link'>Rejected</span>`;
                        }else if(data === 'closed') {
                            return `<span class='badge text-bg-secondary nav-link'>Closed</span>`;
                        }else{
                            return `<span class='badge text-bg-primary nav-link'>${data}</span>`;
                        }
                        
                    },
                    // createdCell: (td, cellData, rowData, row, col) => {
                    //     if (cellData === 'pending') {
                    //         $(td).addClass('badge text-bg-warning');
                    //     } else if (cellData === 'follow up') {
                    //         $(td).addClass('badge text-bg-info');
                    //     }else if(cellData === 'rejected') {
                    //         $(td).addClass('badge text-bg-danger');
                    //     }else if(cellData === 'closed') {
                    //         $(td).addClass('badge text-bg-secondary');
                    //     }
                    // }
                },
                // {
                //     data: 'status',
                //     render: function (data, type, row) {
                //         return `
                //         <select class='form-control' value='${data}' >
                //             <option value='pending'>Pending</option>
                //             <option value='follow up'>Follow up</option>
                //             <option value='rejected'>Rejected</option>
                //             <option value='closed'>Closed</option>
                //         </select>`;
                //     }
                // },
                // { data: 'submitted_on' }
            ],
            responsive: true
        });


        $('#joinWodoTable tbody').on('click', '.joinwodoviewbtn', function (event) {
            event.preventDefault();
            const rowData = table.row($(this).parents('tr')).data();
            // const rowData = table.row(this).data();
            setViewModalData(rowData);
            handleViewModalShow();
        });
    }, [data, handleViewModalShow,ViewModalData]);



    function PageContent() {
        return (
            <div className="container-fluid">
                <table id="joinWodoTable" className="display tab_tables fs16" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Submitted</th>
                            <th>id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Applying for</th>
                            <th>Role</th>
                            {/* <th>Qualification</th> */}
                            <th>Experience</th>
                            <th>Resume</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                </table>
            </div>
        );
    }

    function ViewModal() {
        const [status, setStatus] = useState(ViewModalData.status);
        const [Updateid, setUpdateId] = useState(ViewModalData.id);

        const handleChange = (e) => {
          setStatus(e.target.value);
        };

        const handleSubmit = async () => {
            try {
                const tab='contactUs'; 
                const response = await axios.put(`${BaseURL}/contact-us/set-status`, { tab,status,Updateid });
                // console.log('Response:', response.data);
                // const currentPage = $('#joinWodoTable').DataTable().page();
                handleViewModalClose();
                // props.dataCount();
                joinWodoFetchData();
                // initializeDataTable(currentPage);
            } catch (error) {
                console.error('Error submitting status:', error);
            }
        };
        return (
            <Modal show={ViewModalshow} backdrop="static" keyboard={false} onHide={handleViewModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Join Wodo #{ViewModalData.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table table-hover'>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{ViewModalData.full_name}</td>
                            </tr>
                            <tr>
                                <th>Email id</th>
                                <td>{ViewModalData.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{ViewModalData.phone}</td>
                            </tr>	
                            <tr>
                                <th>Linkedin</th>
                                <td>{ViewModalData.linkedin}</td>
                            </tr>
                            <tr>
                                <th>Applying For</th>
                                <td>{ViewModalData.applying_for}</td>
                            </tr>
                            <tr>
                                <th>Role</th>
                                <td>{ViewModalData.role}</td>
                            </tr>

                            <tr>
                                <th>Qualification</th>
                                <td>{ViewModalData.qualification}</td>
                            </tr>

                            {ViewModalData.applying_for==='Full Time'
                                ?
                                <tr>
                                    <th>Experience</th>
                                    <td>{ViewModalData.experience}</td>
                                </tr>
                                :
                                ''
                            }
                            <tr>
                                <th>Portfolio</th>
                                <td>{ViewModalData.portfolio}</td>
                            </tr>                        
                            <tr>
                                <th>Resume</th>
                                <td><a href={BaseURL+ViewModalData.resume} target='_blank'>File</a></td>
                            </tr>                        
                            <tr>
                                <th>Status</th>
                                <td>
                                    <select name="" id="" className='form-select'  onChange={handleChange} value={status}>
                                        <option value='pending'>Pending</option>
                                        <option value='follow up'>Follow up</option>
                                        <option value='rejected'>Rejected</option>
                                        <option value='closed'>Closed</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Submitted</th>
                                <td>{ViewModalData.submitted_on}</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' variant="secondary" onClick={handleViewModalClose}>
                        Close
                    </Button>
                    <Button size='sm' variant="warning" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div>
            <ViewModal />
            <PageContent />
        </div>
    );
}

export default JoinWodo;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import $ from 'jquery';
// import './style.css'
// import DataTable from 'datatables.net-bs5';
// import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
// import BaseURL from '../../components/Other/BaseURL';
// import Template from '../../components/Template/Template';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

// function JoinWodo(props) {
//     // const [data, setData] = useState(props.joinwododata);
//     const data=props.joinwododata;
//     const [ViewModalshow, setViewModalShow] = useState(false);
//     const handleViewModalClose = () => setViewModalShow(false);
//     const handleViewModalShow = () => setViewModalShow(true);
//     const [ViewModalData, setViewModalData] = useState([]);
  

//     // useEffect(() => {
//     //     fetchData();
//     // }, []);

//     // const fetchData = async () => {
//     //     try {
//     //         const response = await axios.get(`${BaseURL}/contact-us/join-wodo`);
//     //         setData(response.data.data);
//     //     } catch (error) {
//     //         console.error('Error fetching data:', error);
//     //     }
//     // };


//     useEffect(() => {
//         initializeDataTable();
//     }, [data]);

//     const initializeDataTable = () => {
//         if ($.fn.DataTable.isDataTable('#joinWodoTable')) {
//             $('#joinWodoTable').DataTable().destroy();
//         }
//         var table = $('#joinWodoTable').DataTable({
//             data: data,
//             columns: [
//                 { data: 'id' },
//                 {
//                     data: 'full_name',
//                     render: function (data, type, row) {
//                         return `<span>${data}</span>
//                         <br>
//                         <button class='btn btn-sm joinwodoviewbtn' >View</button>
//                         `;
//                     }
//                 },
//                 { data: 'email' },
//                 { data: 'phone' },
//                 // { data: 'linkedin' },
//                 { data: 'applying_for' },
//                 { data: 'role' },
//                 { data: 'qualification' },
//                 { data: 'experience' },
//                 // { data: 'portfolio' },
//                 {
//                     data: 'resume',
//                     render: function (data, type, row) {
//                         return `<a class='badge text-bg-warning nav-link' href='`+BaseURL+data + `'> FILE </a>
//                         `;
//                     }
//                 },
//                 {
//                     data: 'status',
//                     render: function (data, type, row) {
//                         return `
//                         <select class='form-control ' value='${data}'>
//                             <option value='pending'>Pending</option>
//                             <option value='follow up'>Follow up</option>
//                             <option value='rejected'>Rejected</option>
//                             <option value='closed'>Closed</option>
//                         </select>
//                         `;
//                     }
//                 },
//                 // { data: 'view_status' },
//                 { data: 'submitted_on' },

                
//             ],

            
//             responsive: true
//         });

//         $('#joinWodoTable tbody').on('click', '.joinwodoviewbtn', function (event) {
//             event.preventDefault();
//             // var rowData = table.row(this).data();
//             const rowData = $('#joinWodoTable').DataTable().row($(this).parents('tr')).data();
//             console.log(rowData);
//             // 

//             setViewModalData(rowData);
//             handleViewModalShow();

            
//             // You can do something with the rowData here
//         });
//     };

//     function PageContent(){
//         return(
//             <>
//                 <div className="container-fluid">
//                     <table id="joinWodoTable" className="display tab_tables" style={{ width: '100%' }}>
//                         <thead>
//                             <tr>
//                                 <th>id</th>
//                                 <th>Name</th>
//                                 <th>Email</th>
//                                 <th>Phone</th>
//                                 {/* <th>linkedin</th> */}
//                                 <th>Applying for</th>
//                                 <th>Role</th>
//                                 <th>Qualification</th>
//                                 <th>Experience</th>
//                                 {/* <th>portfolio</th> */}
//                                 <th>Resume</th>
//                                 <th>Status</th>
//                                 {/* <th>view_status</th> */}
//                                 <th>Submitted</th>
//                             </tr>
//                         </thead>
//                     </table>
//                 </div>
//             </>
//         );
//     }

//     function ViewModal(){

//         return (
//           <>
      
//             <Modal show={ViewModalshow} onHide={handleViewModalClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Modal heading</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>Woohoo, you are reading this text in a modal!
//                     {ViewModalData.full_name}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleViewModalClose}>
//                     Close
//                     </Button>
//                     <Button variant="primary" onClick={handleViewModalClose}>
//                     Save Changes
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//           </>
//         );
//     }

//     return (
//         <div>
//             <ViewModal/>
//             {/* <Template currentpage={props.currentpage}> */}
//             <PageContent />

//             {/* </Template> */}
//         </div>
//     )
// }

// export default JoinWodo
import React, { useState } from 'react';
import './style.css';
import Cookies from 'js-cookie';
import { useNavigate,useParams } from 'react-router-dom';
import Logo from '../assets/logo.png'
function Login() {
    const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform login logic here, such as sending email and password to an API
    console.log('Email:', email);
    console.log('Password:', password);

    if(email=='hello@wodo.digital' && password=="123"){
        Cookies.set('login', email, { expires: 3 });
        navigate('/dashboard')
    }else{
        console.log('Incorrect')
    }


  };

  return (
    <>
      <div className='LoginBackground'>
        <div className="container" style={{ position: 'relative' }}>
          <div className="row" style={{ height: '100vh' }}>
            <div className="col-md"></div>
            <div className="col-md verticle_center_login">
              <div className="loginBox">
                <div style={{textAlign:'center'}}>
                    <img src={Logo} alt="" style={{width:110}}/>
                </div>
                <h5 style={{textAlign:'left'}}>Admin Login</h5>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <button type="submit" className="btn btn-warning">Submit</button>
                </form>
              </div>
            </div>
            <div className="col-md"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

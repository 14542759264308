import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Logout() {
  const navigate = useNavigate();
  const id = Cookies.get('login')

  useEffect(() => {
    // Clear the 'login' cookie when the component mounts
    Cookies.remove('login');
    // Navigate to the login page after clearing the cookie
    navigate('../'  );
    window.location.reload();
  }, [navigate,id]);

  return (
    <div>
      {/* <p>Logging out...</p> */}
    </div>
  );
}

export default Logout;

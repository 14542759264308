import { 
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import './App.css';
import Login from './auth/Login';
import Logout from './auth/logout';
import Dashboard from './app/dashboard/Dashboard';
import Template from './app/template/Template';
import Blogs from './app/blogs/Blogs';
import EditBlog from './app/blogs/EditBlog';
import AddBlog from './app/blogs/AddBlog';
import FileUpload from './app/blogs/BlogComponents/FileUpload';
import ContactUs from './app/contactUs/ContactUs';
import ContactUs2 from './app/contactUs/ContactUs2';
import ContactUs3 from './app/contactUs/TabContents/JoinWodo';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/logout' element={<Logout  />}/>
          
          <Route path='/dashboard' element={<Dashboard currentpage='dashboard' />}/>
          <Route path='/blogs' element={<Blogs currentpage='blogs' />}/>
          <Route path='/edit-blog/:slug' element={<EditBlog currentpage='blogs' />}/>
          <Route path='/file-upload' element={<FileUpload currentpage='blogs' />}/>
          <Route path='/add-blog' element={<AddBlog currentpage='blogs' />}/>
          <Route path='/template'  element={<Template currentpage='template'/>}/>
          <Route path='/contact-us' element={<ContactUs currentpage='contact-us'/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
